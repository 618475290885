import React from "react";
import { useParams } from "react-router-dom";

const data = {
  fire_insurance: {
    title: "Fire Insurance",
    definition:
      "Fire insurance is a form of property insurance that covers damage and losses caused by fire. Most policies come with some form of fire protection, but homeowners may be able to purchase additional coverage in case their property is lost or damaged because of fire. Purchasing additional fire coverage helps to cover the cost of replacement, repair, or reconstruction of property above the limit set by the property insurance policy. Fire insurance policies typically contain general exclusions such as war, nuclear risks, and similar perils. Damage caused by a fire set deliberately is also typically not covered.",
    how_it_works: [
      "A standard homeowners insurance policy usually includes fire insurance. Homeowners insurance provides policyholders with coverage against loss and damage to their homes and possessions, also referred to as insured property. Insured property includes the interior and exterior of the home and any assets kept on the property.",
      "Policies may also cover injuries that someone sustains while on the property. If you have a mortgage, your mortgage lender will likely require that you have homeowners insurance before it approves the loan.",
      "You can also purchase fire insurance as a stand-alone policy. It covers fire loss or damage from a number of sources including electricity, faulty wiring, gas explosions, lightning, and natural disasters. The policy may also cover burst pipes and overflowing water tanks."
    ],
    who_should_buy_it: [
      "Educational institutes",
      "Businesses",
      "Manufacturing firms",
      "Individuals",
      "Service providers",
      "Other institutes"
    ],
    characteristics: [
      "Requires full disclosure of property details",
      "Personal insurance contract",
      "Fire needs to be the only cause of loss",
      "Annual contract",
      "Insurable interest",
      "Principle of indemnity"
    ],
    faqs: [
      {
        question: "Is Fire Insurance Different From Homeowners Insurance?",
        answer:
          "Fire insurance provides coverage for costs related to a fire, whereas homeowners insurance protects against many other types of risks. A homeowners insurance policy typically includes coverage for damage caused by a fire, but a stand-alone fire insurance policy may provide more extensive coverage."
      },
      {
        question: "What Is Not Covered in Fire Insurance?",
        answer:
          "A fire insurance policy will not cover losses related to fires set deliberately. Fire insurance also only covers losses related to a fire, so if your property suffers loss or damage from another cause, it would not be covered."
      },
      {
        question: "What Type of Property Is Covered by Fire Insurance?",
        answer:
          "Typically, any fire damage to your home or its contents will be covered by fire insurance. This includes property covered by candle fires, grease fires, electrical fires, and others."
      },
      {
        question: "What does fire insurance typically cover?",
        answer: [
          "Damage to buildings and structures.",
          "Damage to personal belongings.",
          "Loss of rental income if you are a landlord.",
          "Temporary living expenses if your home is uninhabitable."
        ]
      },
      {
        question: "Does fire insurance cover all types of fires?",
        answer:
          "Most standard fire insurance policies cover damage caused by fire, but exclusions may apply. For example, damage caused by wildfires might be excluded in some areas, and some policies may not cover damage due to arson or negligence."
      },
      {
        question: "Are there any exclusions in fire insurance policies?",
        answer: [
          "Damage caused by intentional acts or arson.",
          "Damage from earthquakes, floods, or other natural disasters (often covered by separate policies).",
          "Wear and tear or gradual deterioration."
        ]
      },
      {
        question: "How do I determine how much coverage I need?",
        answer: [
          "Conduct a thorough inventory of your possessions.",
          "Get a professional appraisal of your property.",
          "Consider potential costs for temporary relocation."
        ]
      },
      {
        question: "How do I file a fire insurance claim?",
        answer: [
          "Ensure Safety: First, ensure everyone is safe and contact emergency services if needed.",
          "Document the Damage: Take photos or videos of the damage.",
          "Notify Your Insurance Company: Contact your insurer as soon as possible to report the claim.",
          "Complete the Claim Form: Fill out any required forms and provide necessary documentation.",
          "Work with an Adjuster: An insurance adjuster may assess the damage and determine the payout."
        ]
      }
    ]
  }
};

function FireInsurance() {
  const { title } = useParams();

  // Find the insurance entry that matches the title from the URL
  const entry = data.fire_insurance;

  return (
    <section className="px-32 py-20 bg-slate-50">
      <div>
        {/* Title and Definition */}
        <div className="bg-slate-200 p-10 border-gray-700 border-2 rounded-xl space-y-4">
          <h1 className="font-semibold text-3xl">{entry.title}</h1>
          <p className="text-xl">{entry.definition}</p>
        </div>

        {/* How It Works */}
        <div className="my-10">
          <h2 className="font-semibold text-2xl">How It Works</h2>
          <ul className="mt-5 space-y-3">
            {entry.how_it_works.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>
        </div>

        {/* Who Should Buy It */}
        <div className="my-10">
          <h2 className="font-semibold text-2xl">Who Should Buy It</h2>
          <ul className="mt-5 space-y-3">
            {entry.who_should_buy_it.map((buyer, index) => (
              <li key={index}>{buyer}</li>
            ))}
          </ul>
        </div>

        {/* Characteristics */}
        <div className="my-10">
          <h2 className="font-semibold text-2xl">Characteristics</h2>
          <ul className="mt-5 space-y-3">
            {entry.characteristics.map((char, index) => (
              <li key={index}>{char}</li>
            ))}
          </ul>
        </div>

        {/* FAQs */}
        <div className="my-10">
          <h2 className="font-semibold text-2xl">FAQs</h2>
          <ul className="mt-5 space-y-3">
            {entry.faqs.map((faq, index) => (
              <li key={index}>
                <strong>{faq.question}</strong>
                <ul className="ml-6">
                  {Array.isArray(faq.answer) ? (
                    faq.answer.map((ans, i) => <li key={i}>{ans}</li>)
                  ) : (
                    <li>{faq.answer}</li>
                  )}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default FireInsurance;

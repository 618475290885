import React from "react";
import { useParams } from "react-router-dom";

const data = {
  travel_insurance: {
    title: "Travel Insurance",
    definition:
      "Travel insurance is a type of insurance covering financial losses associated with traveling, and it can be useful protection for domestic or international travel. It covers medical expenses, trip cancellation, lost luggage, flight accidents, and other losses incurred while traveling.",
    types_of_plans: [
      "Package Plan Travel Insurance",
      "Travel Medical Insurance",
      "Medical Evacuation Travel Insurance",
      "Flight Accident Insurance",
      "Annual Travel Insurance",
      "Rental Car Travel Insurance",
      "Kidnap and Ransom Travel Insurance",
      "Medical Tourism Travel Insurance",
      "Term Life Travel Insurance",
    ],
    trip_cancellation_or_interruption_coverage: {
      trip_cancellation:
        "Reimburses you for paid travel expenses if you can't travel for a preapproved reason.",
      travel_delay:
        "Reimburses you for expenses if you can't travel because of a delay.",
      trip_interruption:
        "Reimburses you for travel costs if your trip is cut short.",
      cancel_for_any_reason:
        "Reimburses you for a portion of costs if you cancel the trip for any reason; typically more expensive than other types.",
    },
    acceptable_claim_reasons: [
      "Your illness",
      "Illness or death in your immediate family",
      "Sudden business conflicts",
      "Weather-related issues",
      "Legal obligations such as jury duty",
      "Bankruptcy",
    ],
    how_to_choose_a_plan: [
      "Cancellation coverage",
      "Baggage losses and delays",
      "Personal liability",
      "Medical expenses",
      "Travel assistance",
      "Ease of passport retrieval",
    ],
    key_takeaways: [
      "Travel insurance can be purchased online, from your tour operator, or from other sources.",
      "The main categories of travel insurance include trip cancellation or interruption coverage, baggage and personal effects coverage, rental property and rental car coverage, medical coverage, and accidental death coverage.",
      "Coverage often includes 24/7 emergency services, such as replacing lost passports, cash wire assistance, and rebooking canceled flights.",
      "It's important to understand what's covered and what's not, and any limitations on coverage amounts and coverage requirements.",
    ],
  },
};

function TravelInsurance() {
  const { title } = useParams();

  // Find the insurance entry that matches the title from the URL
  const entry = data.travel_insurance;

  return (
    <section className="px-32 py-20 bg-slate-50">
      <div>
        {/* Title and Definition */}
        <div className="bg-green-200 p-10 border-gray-700 border-2 rounded-xl space-y-4">
          <h1 className="font-semibold text-3xl">{entry.title}</h1>
          <p className="text-xl">{entry.definition}</p>
        </div>

        {/* Types of Plans */}
        <div className="my-10">
          <h2 className="font-semibold text-2xl">Types of {entry.title}</h2>
          <ul className="mt-5 space-y-3">
            {entry.types_of_plans.map((type, index) => (
              <li key={index}>
                <strong className="space-x-1">
                  {index + 1}. {type}
                </strong>
              </li>
            ))}
          </ul>
        </div>

        {/* Trip Cancellation or Interruption Coverage */}
        <div className="my-10">
          <h2 className="font-semibold text-2xl">
            Trip Cancellation or Interruption Coverage
          </h2>
          <ul className="mt-5 space-y-3">
            {Object.entries(
              entry.trip_cancellation_or_interruption_coverage
            ).map(([key, value], index) => (
              <li key={index}>
                <strong className="space-x-1">{key.replace(/_/g, " ")}:</strong>{" "}
                {value}
              </li>
            ))}
          </ul>
        </div>

        {/* Acceptable Claim Reasons */}
        <div className="my-10">
          <h2 className="font-semibold text-2xl">Acceptable Claim Reasons</h2>
          <ul className="mt-5 space-y-3 flex space-x-4 flex-col md:flex-row">
            {entry.acceptable_claim_reasons.map((reason, index) => (
              <li key={index}>
                <div className="h-40 w-40 bg-green-200 font-semibold text-xl border-gray-950 border-2 justify-center items-center p-3 text-center rounded-xl">
                  {reason}
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* Key Takeaways */}
        <div className="mt-10">
          <h2 className="font-semibold text-2xl mb-4">Key Takeaways</h2>
          <ul className="space-y-2">
            {entry.key_takeaways.map((takeaway, index) => (
              <li key={index}> <strong className="text-3xl font-extrabold">.</strong> {takeaway}</li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default TravelInsurance;

import React from "react";
// import { useParams } from "react-router-dom";
import policy from "../Data/PageData.json";

const data = {
  liability_insurance: {
    link: "motorInsurance",
    title: "Liability Insurance",
    definition:
      "Liability insurance is an insurance product that provides protection against claims resulting from injuries and damage to other people or property. Liability insurance policies cover any legal costs and payouts an insured party is responsible for if they are found legally liable. Intentional damage and contractual liabilities are generally not covered in liability insurance policies. Unlike other types of insurance, liability insurance policies pay third parties, and not policyholders.",
    how_it_works: {
      description:
        "Liability insurance is critical for those who are liable and at fault for injuries sustained by other people or in the event that the insured party damages someone else's property. As such, liability insurance is also called third-party insurance. Liability insurance does not cover intentional or criminal acts even if the insured party is found legally responsible. Policies are taken out by anyone who owns a business, drives a car, practices medicine or law—basically anyone who can be sued for damages and/or injuries. Policies protect both the insured and third parties who may be injured as a result of the policyholder's unintentional negligence.",
      examples: [
        "Most states require that vehicle owners have liability insurance under their automotive insurance policies to cover injury to other people and property in the event of accidents.",
        "A product manufacturer may purchase product liability insurance to cover them if a product is faulty and causes damage to the purchasers or another third party.",
        "Business owners may purchase liability insurance that covers them if an employee is injured during business operations.",
        "The decisions doctors and surgeons make while on the job also require liability insurance policies.",
      ],
    },
    types_of_liability_insurance: [
      {
        type: "Employer's liability and workers' compensation",
        description:
          "Mandatory coverage for employers which protects the business against liabilities arising from injuries or the death of an employee.",
      },
      {
        type: "Product liability insurance",
        description:
          "For businesses that manufacture products for sale on the general market. It protects against lawsuits arising from injury or death caused by their products.",
      },
      {
        type: "Indemnity insurance",
        description:
          "Provides coverage to protect a business against negligence claims due to financial harm resulting from mistakes or failure to perform.",
      },
      {
        type: "Director and officer liability coverage",
        description:
          "Covers a company's board of directors or officers against liability if the company should be sued. Some companies provide additional protection to their executive team even though corporations generally provide some degree of personal protection to their employees.",
      },
      {
        type: "Umbrella liability policies",
        description:
          "Personal liability policies designed to protect against catastrophic losses. Coverage generally kicks in when the liability limits of other insurance are reached.",
      },
      {
        type: "Commercial liability insurance",
        description:
          "A standard commercial general liability policy, also known as comprehensive general liability insurance. It provides coverage for lawsuits arising from injury to employees and the public, property damage caused by an employee, as well as injuries suffered by the negligent action of employees. The policy may also cover infringement on intellectual property, slander, libel, contractual liability, tenant liability, and employment practices liability.",
      },
      {
        type: "Comprehensive general liability policies",
        description:
          "Tailor-made for any small or large business, partnership or joint venture businesses, a corporation or association, an organization, or even a newly acquired business. Insurance coverage includes bodily injury, property damage, personal and advertising injury, medical payments, and premises and operations liability.",
      },
    ],
    personal_vs_business_liability_insurance: {
      personal_liability:
        "Personal liability insurance covers individuals against claims resulting from injuries or damage to other people or property experienced on the insured's property or as a result of the insured's actions.",
      business_liability:
        "Business liability insurance protects the financial interests of companies and business owners from lawsuits or damages resulting from similar accidents but also extending to product defects, recalls, and more.",
    },
    umbrella_insurance: {
      question: "What is Umbrella Insurance?",
      answer:
        "An umbrella insurance policy is additional liability insurance coverage that is purchased and goes beyond the dollar limits of the insured's existing homeowners, auto, or watercraft insurance. Umbrella policies tend to be affordable and offered in increments of $500,000 or $1 million.",
    },
    backdated_liability_coverage: {
      question: "What is Backdated Liability Coverage?",
      answer:
        "Backdated liability insurance is insurance that provides coverage for a claim that occurred before the insurance policy was purchased. These policies are uncommon and usually available only to businesses.",
    },
    key_takeaways: [
      "Liability insurance provides protection against claims resulting from injuries and damage to people and/or property.",
      "Liability insurance covers legal costs and payouts for which the insured party would be found liable.",
      "Provisions not covered include intentional damage, contractual liabilities, and criminal prosecution.",
      "Liability insurance is often required for automotive insurance policies, product manufacturers, and anyone who practices medicine or law.",
      "Personal liability, workers' compensation, and commercial liability are types of liability insurance.",
    ],
  },
};

function InsuranceDetail() {
  // const { title } = useParams();

  // Find the insurance entry that matches the title from the URL
  // const entry = Object.values(data).find((item) => item.link === title);

  const entry = data.liability_insurance;
  // // If no entry is found, return a message
  // if (!entry) {
  //   return <div className="px-32 py-20">Insurance type not found.</div>;
  // }

  // console.log(title, data.liability_insurance.link);
  // console.log(policy);
  return (
    <section className="px-32 py-20 bg-slate-50">
      <div>
        <div className="bg-slate-200 p-10 border-gray-700 border-2 rounded-xl space-y-4">
          <h1 className="font-semibold text-3xl">{entry.title}</h1>
          <p className="text-xl">{entry.definition}</p>
        </div>

        <div className=" my-10">
          <div className="space-y-2">
            <h2 className="font-semibold text-2xl">How It Works</h2>
            <p className="">{entry.how_it_works.description}</p>
          </div>
          <div className="p-5 pr-20 space-y-2">
            <ol className="mt-4 spcae-y-3">
              {entry.how_it_works.examples.map((example, index) => (
                <li className="mt-4" key={index}>{`${
                  index + 1
                }. ${example}`}</li>
              ))}
            </ol>
          </div>
        </div>
        <div className="">
          <h2 className="font-semibold text-2xl  ">Types of {entry.title}</h2>
          <ul className="mt-5 space-y-3">
            {entry.types_of_liability_insurance.map((type, index) => (
              <li key={index}>
                <strong className="space-x-1">
                  {index + 1} . {type.type}
                </strong>
                :<div className="ml-6">{type.description}</div>
              </li>
            ))}
          </ul>
        </div>

        <div className="mt-10 ">
          <h2 className="font-semibold text-2xl mb-4">Key Takeaways</h2>
          <ul className="space-y-2">
            {entry.key_takeaways.map((takeaway, index) => (
              <li key={index}>{takeaway}</li>
            ))}
          </ul>
        </div>

        {/* You can add more sections here like umbrella insurance, personal vs business, etc. */}
      </div>
    </section>
  );
}

// const InsuranceDetail = () =>{
//   return (
//     <>
//     <div>
//       Hello
//     </div>
//     </>
//   )
// }

export default InsuranceDetail;

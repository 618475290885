import React from "react";
import { useParams } from "react-router-dom";

const data = {
  marine_insurance: {
    title: "Marine Insurance",
    definition:
      "Marine insurance covers losses or damages to cargo or goods during transportation between the points of origin and the final destination. It applies to all modes of transport, such as road, railway, air, sea, couriers, and postal service, and covers risks like fire, explosion, hijacks, accidents, and collisions.",
    who_can_buy: [
      "Exporters / Importers",
      "Manufacturers",
      "Traders",
      "Merchant Exporters",
      "Project Contractors",
      "Logistics Operators",
      "C&F Agents",
      "Anyone involved in the movement of goods",
    ],
    types_of_cover: [
      "Damage to cargo or vessel due to fire, explosion, sinking, or stranding during the journey",
      "Expenses from collisions, overturning, or derailment of land conveyance",
      "Compensation for expenses if the cargo is discharged from port of distress/disturbance",
      "Damage from natural calamities such as earthquakes or lightning",
      "Survey fees, forwarding expenses, reconditioning costs, and legal charges",
      "Jettison and washing overboard expenses",
      "Total loss of package during loading or unloading",
    ],
    cost_factors: [
      "Value of the cargo",
      "Condition of the cargo",
      "Route of the shipping vessel",
      "Company’s claim history",
      "Insurer’s assessment",
    ],
    advantages: [
      "Risk mitigation: Provides protection against financial losses resulting from risks such as damage to the vessel, cargo, or liability for third-party injuries and property damage.",
      "Business continuity: In the event of a covered loss, marine insurance helps maintain financial stability by compensating for damages.",
      "Global coverage: Offers coverage for vessels navigating international waters, providing protection for businesses engaged in global trade.",
      "Customizable coverage: Tailored policies cover different aspects such as hull insurance, cargo insurance, and liability coverage.",
    ],
    disadvantages: [
      "Cost: Premiums can be high, especially in high-risk areas or for companies with a history of claims.",
      "Complex policies: Conditions, exclusions, and deductibles can make policies challenging to understand.",
      "Deductibles: The insured party must contribute a certain amount toward a claim before the insurance coverage takes effect.",
      "Exclusions and limitations: Policies may have exclusions and limitations that affect coverage.",
    ],
  },
};

function MarineInsurance() {
  const { title } = useParams();

  // Find the insurance entry that matches the title from the URL
  const entry = data.marine_insurance;

  return (
    <section className="px-32 py-20 bg-slate-50">
      <div>
        {/* Title and Definition */}
        <div className="bg-slate-200 p-10 border-gray-700 border-2 rounded-xl space-y-4">
          <h1 className="font-semibold text-3xl">{entry.title}</h1>
          <p className="text-xl">{entry.definition}</p>
        </div>

        {/* Who Can Buy */}
        <div className="my-10">
          <h2 className="font-semibold text-2xl">Who Can Buy {entry.title}</h2>
          <ul className="mt-5 space-y-3">
            {entry.who_can_buy.map((buyer, index) => (
              <li key={index}>{buyer}</li>
            ))}
          </ul>
        </div>

        {/* Types of Cover */}
        <div className="my-10">
          <h2 className="font-semibold text-2xl">Types of Cover</h2>
          <ul className="mt-5 space-y-3">
            {entry.types_of_cover.map((cover, index) => (
              <li key={index}>{cover}</li>
            ))}
          </ul>
        </div>

        {/* Cost Factors */}
        <div className="my-10">
          <h2 className="font-semibold text-2xl">Cost Factors</h2>
          <ul className="mt-5 space-y-3">
            {entry.cost_factors.map((factor, index) => (
              <li key={index}>{factor}</li>
            ))}
          </ul>
        </div>

        {/* Advantages */}
        <div className="my-10">
          <h2 className="font-semibold text-2xl">Advantages</h2>
          <ul className="mt-5 space-y-3">
            {entry.advantages.map((advantage, index) => (
              <li key={index}>{advantage}</li>
            ))}
          </ul>
        </div>

        {/* Disadvantages */}
        <div className="my-10">
          <h2 className="font-semibold text-2xl">Disadvantages</h2>
          <ul className="mt-5 space-y-3">
            {entry.disadvantages.map((disadvantage, index) => (
              <li key={index}>{disadvantage}</li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default MarineInsurance;

import React from "react";
import { useParams } from "react-router-dom";

const data = {
  motor_insurance: {
    title: "Motor Insurance",
    definition:
      "Vehicle insurance or motor insurance is similar to any other insurance coverage, except that it is mandatory. As the name implies, it is insurance for all types of motor vehicles - motorcycles, cars, jeeps, commercial vehicles, and so on. The government has made motor insurance necessary for your protection and the safety of others.",
    types_of_insurance: [
      {
        type: "Private Insurance Policy",
        description:
          "The Government of India requires automobile insurance for any private car owned by a person. Private car insurance protects the vehicle from damage caused by accidents, fire, natural disasters, and theft, as well as the owner from personal injury. It also safeguards the third party against any losses or injuries."
      },
      {
        type: "Commercial Vehicle Insurance",
        description:
          "All vehicles that are not utilized for personal reasons are covered by a commercial vehicle insurance policy. This covers any automobiles used for business purposes such as trucks, buses, ambulances, auto-rickshaws, and more."
      }
    ],
    how_to_choose_plan: [
      "Look for comprehensive cover",
      "Check insured declared value",
      "Check for additional covers",
      "Opt for no claim bonus"
    ],
    types_of_motor_insurance: [
      "Third party motor insurance",
      "Standalone own damage cover",
      "Comprehensive motor insurance"
    ],
    faqs: [
      {
        question: "Is motor insurance mandatory?",
        answer:
          "In many countries, third-party insurance is mandatory. Comprehensive insurance is often optional but recommended for better protection."
      },
      {
        question: "What does comprehensive motor insurance cover?",
        answer: [
          "Damage to your own vehicle from accidents, vandalism, or natural events.",
          "Theft or attempted theft of your vehicle.",
          "Fire damage.",
          "Third-party liability for injuries or property damage."
        ]
      },
      {
        question: "What is not covered by motor insurance?",
        answer: [
          "Wear and tear or mechanical breakdowns.",
          "Intentional damage or criminal acts.",
          "Driving under the influence of drugs or alcohol.",
          "Using the vehicle for purposes not specified in the policy (e.g., commercial use if only covered for personal use)."
        ]
      },
      {
        question: "How do I file a claim?",
        answer: [
          "Report the incident to the police if required.",
          "Notify your insurance provider as soon as possible.",
          "Provide necessary documentation, such as a police report, photographs, and details of the incident.",
          "Cooperate with the insurance company’s investigation and assessment."
        ]
      },
      {
        question: "Will my insurance cover me if I drive abroad?",
        answer:
          "Coverage for driving abroad varies by policy. Check with your insurer about international coverage and any additional requirements or documentation needed."
      },
      {
        question: "How is my premium calculated?",
        answer: [
          "Your driving history.",
          "The make and model of your vehicle.",
          "Your location.",
          "Your age and driving experience.",
          "The level of coverage you choose."
        ]
      }
    ]
  }
};

function MotorInsurance() {
  const { title } = useParams();

  // Find the insurance entry that matches the title from the URL
  const entry = data.motor_insurance;

  return (
    <section className="px-32 py-20 bg-slate-50">
      <div>
        {/* Title and Definition */}
        <div className="bg-slate-200 p-10 border-gray-700 border-2 rounded-xl space-y-4">
          <h1 className="font-semibold text-3xl">{entry.title}</h1>
          <p className="text-xl">{entry.definition}</p>
        </div>

        {/* Types of Insurance */}
        <div className="my-10">
          <h2 className="font-semibold text-2xl">Types of {entry.title}</h2>
          <ul className="mt-5 space-y-3">
            {entry.types_of_insurance.map((type, index) => (
              <li key={index}>
                <strong className="space-x-1">
                  {index + 1}. {type.type}
                </strong>
                :<div className="ml-6">{type.description}</div>
              </li>
            ))}
          </ul>
        </div>

        {/* How to Choose a Plan */}
        <div className="my-10">
          <h2 className="font-semibold text-2xl">How to Choose a Plan</h2>
          <ul className="mt-5 space-y-3">
            {entry.how_to_choose_plan.map((tip, index) => (
              <li key={index}>{tip}</li>
            ))}
          </ul>
        </div>

        {/* Types of Motor Insurance */}
        <div className="my-10">
          <h2 className="font-semibold text-2xl">Types of Motor Insurance</h2>
          <ul className="mt-5 space-y-3">
            {entry.types_of_motor_insurance.map((type, index) => (
              <li key={index}>{type}</li>
            ))}
          </ul>
        </div>

        {/* FAQs */}
        <div className="my-10">
          <h2 className="font-semibold text-2xl">FAQs</h2>
          <ul className="mt-5 space-y-3">
            {entry.faqs.map((faq, index) => (
              <li key={index}>
                <strong>{faq.question}</strong>
                <ul className="ml-6">
                  {Array.isArray(faq.answer) ? (
                    faq.answer.map((ans, i) => <li key={i}>{ans}</li>)
                  ) : (
                    <li>{faq.answer}</li>
                  )}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default MotorInsurance;

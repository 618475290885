import React from "react";
import { useParams } from "react-router-dom";

const data = {
  health_and_accidental_insurance: {
    title: "Health and Accidental Insurance",
    definition:
      "Health insurance is a contract between a company and a consumer where the company agrees to pay all or some of the insured person's healthcare costs in return for a monthly premium. It covers medical expenses related to illness, injury, pregnancy, or preventative care.",
    who_needs_it:
      "Everyone needs health insurance to offset the costs of minor and major medical issues, including surgeries and treatments for life-threatening or debilitating conditions.",
    how_to_get_it: {
      employer:
        "If your employer offers health insurance, you may be covered, though you might have to pay part of the costs.",
      self_employed:
        "Self-employed individuals can purchase health insurance through federal or state Health Insurance Marketplaces.",
      medicare:
        "People over 65 can enroll in Medicare, while low-income individuals and families may qualify for Medicaid or subsidized programs.",
    },
    key_takeaways: [
      "Health insurance covers most medical and surgical expenses, as well as preventative care.",
      "Higher premiums generally mean lower out-of-pocket costs.",
      "Plans have deductibles and co-pays, but federal law caps these out-of-pocket expenses.",
      "Government programs like Medicare, Medicaid, and CHIP extend coverage to seniors, the disabled, and low-income families.",
    ],
    how_to_choose_your_plan: [
      "Pre and post hospitalization",
      "Hospitalization bills",
      "ICU charges",
      "Pre-existing diseases",
      "Medical checkups",
      "Ambulance costs",
    ],
    faqs: [
      {
        question: "What types of health insurance are available?",
        answer: [
          "Individual Health Insurance: Coverage purchased by individuals or families.",
          "Group Health Insurance: Typically provided through employers or organizations.",
          "Government Programs: Medicare, Medicaid, and CHIP.",
          "Short-Term Health Insurance: Temporary coverage lasting a few months to a year.",
        ],
      },
      {
        question: "What does health insurance typically cover?",
        answer: [
          "Hospitalization",
          "Doctor visits",
          "Preventive services (e.g., vaccinations, screenings)",
          "Prescription drugs",
          "Emergency services",
          "Mental health services",
        ],
      },
      {
        question: "Are there services health insurance doesn’t cover?",
        answer: [
          "Cosmetic surgery",
          "Certain experimental treatments",
          "Elective procedures",
          "Over-the-counter medications",
        ],
      },
      {
        question: "How can I enroll in health insurance?",
        answer: [
          "Employer-Sponsored Plans: During open enrollment or qualifying life events.",
          "Health Insurance Marketplaces: During open enrollment or qualifying life events.",
          "Government Programs: Through Medicare, Medicaid, or CHIP.",
        ],
      },
      {
        question: "What is open enrollment?",
        answer:
          "A period during which you can sign up for or make changes to your health insurance plan.",
      },
      {
        question: "What are qualifying life events?",
        answer: [
          "Marriage or divorce",
          "Birth or adoption of a child",
          "Loss of other coverage",
          "Moving to a new state",
        ],
      },
    ],
  },
};

function HealthAndAccidentalInsurance() {
  const { title } = useParams();

  // Find the insurance entry that matches the title from the URL
  const entry = data.health_and_accidental_insurance;

  return (
    <section className="px-32 py-20 bg-slate-50">
      <div>
        {/* Title and Definition */}
        <div className="bg-slate-200 p-10 border-gray-700 border-2 rounded-xl space-y-4">
          <h1 className="font-semibold text-3xl">{entry.title}</h1>
          <p className="text-xl">{entry.definition}</p>
        </div>

        {/* Who Needs It */}
        <div className="my-10">
          <h2 className="font-semibold text-2xl">Who Needs It</h2>
          <p>{entry.who_needs_it}</p>
        </div>

        {/* How to Get It */}
        <div className="my-10">
          <h2 className="font-semibold text-2xl">How to Get It</h2>
          <ul className="mt-5 space-y-3">
            <li>
              <strong>Employer:</strong> {entry.how_to_get_it.employer}
            </li>
            <li>
              <strong>Self-Employed:</strong>{" "}
              {entry.how_to_get_it.self_employed}
            </li>
            <li>
              <strong>Medicare:</strong> {entry.how_to_get_it.medicare}
            </li>
          </ul>
        </div>

        {/* Key Takeaways */}
        <div className="my-10">
          <h2 className="font-semibold text-2xl">Key Takeaways</h2>
          <ul className="mt-5 space-y-3">
            {entry.key_takeaways.map((takeaway, index) => (
              <li key={index}>{takeaway}</li>
            ))}
          </ul>
        </div>

        {/* How to Choose Your Plan */}
        <div className="my-10">
          <h2 className="font-semibold text-2xl">How to Choose Your Plan</h2>
          <ul className="mt-5 space-y-3">
            {entry.how_to_choose_your_plan.map((tip, index) => (
              <li key={index}>{tip}</li>
            ))}
          </ul>
        </div>

        {/* FAQs */}
        <div className="my-10">
          <h2 className="font-semibold text-2xl">FAQs</h2>
          <ul className="mt-5 space-y-3">
            {entry.faqs.map((faq, index) => (
              <li key={index}>
                <strong>{faq.question}</strong>
                <ul className="ml-6">
                  {Array.isArray(faq.answer) ? (
                    faq.answer.map((ans, i) => <li key={i}>{ans}</li>)
                  ) : (
                    <li>{faq.answer}</li>
                  )}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default HealthAndAccidentalInsurance;

import React from "react";
import { useParams } from "react-router-dom";

const data = {
  engineering_insurance: {
    title: "Engineering Insurance",
    definition:
      "Engineering insurance is a comprehensive policy that covers risks related to ongoing construction projects, installation projects, machines, and equipment in operation.",
    types_of_plans: [
      "EAR Insurance (Erection All Risk)",
      "ALOP Insurance (Advanced Loss of Profit)",
      "CPM Insurance (Contractors Plant and Machinery)",
      "BPM Insurance (Boiler and Pressure Plant)",
      "EEI Insurance (Engineer Electronic Insurance)",
      "MBD Insurance (Machinery Breakdown)",
      "CAR Insurance (Contractors All Risk)"
    ],
    benefits: {
      material_damage:
        "Coverage for loss, damage, or destruction of property from causes not excluded in the policy.",
      third_party_liability: {
        property_damage: "Legal liability for accidental damage to third-party property.",
        personal_injury: "Legal liability for non-fatal and fatal injuries caused to third parties due to the construction of the property."
      }
    },
    coverage: [
      "Fire and allied perils",
      "Collapse",
      "Earthquake, shock, and fire",
      "Faults in construction",
      "Storm, flood, cyclone, and tempest",
      "Negligence and human errors",
      "Water damage"
    ],
    faqs: [
      {
        question: "Who Needs Engineering Insurance?",
        answer: "Construction companies, engineering firms, manufacturers, equipment owners, and project owners."
      },
      {
        question: "How Can I Purchase Engineering Insurance?",
        answer: "You can purchase through insurance brokers or directly from insurance providers."
      },
      {
        question: "Are There Exclusions in Engineering Insurance Policies?",
        answer: "Yes, exclusions may include wear and tear, intentional acts, and political risks."
      },
      {
        question: "How Can I File a Claim?",
        answer: "Notify your insurer, provide documentation, and follow up with the insurance company."
      }
    ]
  }
};

function EngineeringInsurance() {
  const { title } = useParams();

  // Find the insurance entry that matches the title from the URL
  const entry = data.engineering_insurance;

  return (
    <section className="px-32 py-20 bg-slate-50">
      <div>
        {/* Title and Definition */}
        <div className="bg-slate-200 p-10 border-gray-700 border-2 rounded-xl space-y-4">
          <h1 className="font-semibold text-3xl">{entry.title}</h1>
          <p className="text-xl">{entry.definition}</p>
        </div>

        {/* Types of Plans */}
        <div className="my-10">
          <h2 className="font-semibold text-2xl">Types of Plans</h2>
          <ul className="mt-5 space-y-3">
            {entry.types_of_plans.map((plan, index) => (
              <li key={index}>{plan}</li>
            ))}
          </ul>
        </div>

        {/* Benefits */}
        <div className="my-10">
          <h2 className="font-semibold text-2xl">Benefits</h2>
          <ul className="mt-5 space-y-3">
            <li>
              <strong>Material Damage:</strong> {entry.benefits.material_damage}
            </li>
            <li>
              <strong>Third-Party Liability:</strong>
              <ul className="ml-6">
                <li>
                  <strong>Property Damage:</strong> {entry.benefits.third_party_liability.property_damage}
                </li>
                <li>
                  <strong>Personal Injury:</strong> {entry.benefits.third_party_liability.personal_injury}
                </li>
              </ul>
            </li>
          </ul>
        </div>

        {/* Coverage */}
        <div className="my-10">
          <h2 className="font-semibold text-2xl">Coverage</h2>
          <ul className="mt-5 space-y-3">
            {entry.coverage.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>

        {/* FAQs */}
        <div className="my-10">
          <h2 className="font-semibold text-2xl">FAQs</h2>
          <ul className="mt-5 space-y-3">
            {entry.faqs.map((faq, index) => (
              <li key={index}>
                <strong>{faq.question}</strong>
                <p>{faq.answer}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default EngineeringInsurance;

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AboutUS from "./components/AboutUS";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import Service from "./components/Service";
import Team from "./components/Team";
import Testimonials from "./components/Testimonials";
import InsuranceDetail from "./pages/InsuranceDetail.tsx";
import TravelInsurance from "./pages/TravelInsurance.jsx";
import MotorInsurance from "./pages/MotorInsurance.jsx";
import FireInsurance from "./pages/FireInsurance.jsx";
import HealthAndAccidentalInsurance from "./pages/HealthAndAccidentalInsurance.jsx";
import EngineeringInsurance from "./pages/EngineeringInsurance.jsx";
import MarineInsurance from "./pages/MarineInsurance.jsx";

function App() {
  return (
    <div className="App ">
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <>
                {/* <Navbar /> */}
                <Header />
                <AboutUS />
                <Service />
                {/* <Testimonials /> */}
                {/* <Team /> */}
                <ContactUs />
                
                {/* <PopupButton /> */}
              </>
            }
          />
          <Route
            exact
            path="/insurance/liabilityInsurance"
            element={
              <>
                <InsuranceDetail />
              </>
            }
          />
          <Route
            exact
            path="/insurance/travelInsurance"
            element={
              <>
                <TravelInsurance />
              </>
            }
          />
          <Route
            exact
            path="/insurance/motorInsurance"
            element={
              <>
                <MotorInsurance />
              </>
            }
          />
          <Route
            exact
            path="/insurance/fireInsurance"
            element={
              <>
                <FireInsurance />
              </>
            }
          />
          <Route
            exact
            path="/insurance/marineInsurance"
            element={
              <>
                <MarineInsurance />
              </>
            }
          />
          <Route
            exact
            path="/insurance/engineerInsurance"
            element={
              <>
                <EngineeringInsurance />
              </>
            }
          />
          <Route
            exact
            path="/insurance/healthInsurance"
            element={
              <>
                <HealthAndAccidentalInsurance />
              </>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

//motorInsurance
//marineInsurance

export default App;

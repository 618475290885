import React from "react";
import ServiceCard from "./ServiceCard";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import EngineeringIcon from "@mui/icons-material/Engineering";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import LocalAirportIcon from "@mui/icons-material/LocalAirport";
import FavoriteIcon from "@mui/icons-material/Favorite";
import GroupsIcon from "@mui/icons-material/Groups";
import DeckIcon from "@mui/icons-material/Deck";

export default function Service() {
  return (
    <>
      <section id="service" className="flex flex-col justify-center items-center">
        <p className="text-xl px-10 mt-5 md:text-3xl font-bold">
          Get the right product in simpler way
        </p>
        <div className="grid gap-2 md:grid-cols-4 sm:grid-col-2 grid-col-1 my-10">
          <ServiceCard
            color={"bg-pink-300"}
            icon={<DirectionsCarFilledIcon />}
            serviceName={"Motor Insurance"}
            route={"motorInsurance"}
          />
          <ServiceCard
            color={"bg-blue-300"}
            icon={<DirectionsBoatIcon />}
            serviceName={"Marine Insurance"}
            route={"marineInsurance"}
          />
          <ServiceCard
            color={"bg-cyan-300"}
            icon={<EngineeringIcon />}
            serviceName={"Engineering Insurance"}
            route={"engineerInsurance"}
          />
          <ServiceCard
            color={"bg-violet-300"}
            icon={<GroupsIcon />}
            serviceName={"Liability Insurance"}
            route={"liabilityInsurance"}
          />
          <ServiceCard
            color={"bg-gray-300"}
            icon={<WhatshotIcon />}
            serviceName={"Fire Insurance"}
            route={"fireInsurance"}
          />
          <ServiceCard
            color={"bg-purple-300"}
            icon={<FavoriteIcon />}
            serviceName={"Health & Accidental"}
            route={"healthInsurance"}
          />
          <ServiceCard
            color={"bg-yellow-300"}
            icon={<LocalAirportIcon />}
            serviceName={"Travel Insurance"}
            route={"travelInsurance"}
          />
          <ServiceCard
            color={"bg-green-300"}
            icon={<DeckIcon />}
            serviceName={"Other Insurance"}
            route={"otherInsurance"}
          />
        </div>
      </section>
    </>
  );
}

import React from "react";

function ServiceCard({ icon, serviceName, color ,route}) {
  return (
    <div
      className={`${color} space-y-10 text-left p-5 rounded-lg relative group`}
      style={{ position: "relative", overflow: "hidden" }}
    >
      <div>{icon}</div>
      <p className="mr-10">{serviceName}</p>

      {/* Overlay div with glassmorphism effect */}
      <div
        className="absolute inset-0 bg-white bg-opacity-10 backdrop-blur-md flex h-36 justify-center items-center opacity-0 transition-opacity duration-300 group-hover:opacity-100 rounded-lg"
        style={{ zIndex: 10 }}
      >
        <a href={`/insurance/${route}`}>
          <button
            className="bg-white text-black px-4 py-2 mb-10 rounded-lg"
            style={{ zIndex: 20 }}
          >
            Show More
          </button>
        </a>
      </div>
    </div>
  );
}

export default ServiceCard;


//https://forms.gle/P4SNerfVoi8mRnvu5

import React, { useState, useEffect, useRef } from "react";
import JoinUsButton from "./JoinUsButton";
import HamburgerIcon from "./HamburgerIcon";
import logo from "../Assessts/logo.webp";
import policiesData from "../Data/Policies.json";

function Navbar() {
  const [isMobile, setIsMobile] = useState(false);
  const [policies, setPolicies] = useState([]);
  const [hoveredPolicyIndex, setHoveredPolicyIndex] = useState(null);
  const [hoveredPolicyPosition, setHoveredPolicyPosition] = useState({
    top: 0,
    left: 0,
  });
  const policyDivRef = useRef(null);

  // Check if the screen width is less than 768px (typical mobile width)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Load policies data
  useEffect(() => {
    setPolicies(policiesData);
  }, []);

  // Function to handle mouse enter on policy
  const handleMouseEnter = (index, event) => {
    const { top, right } = event.target.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const subheadingDivWidth = 500; // Adjust this to the actual width of your subheading div

    let adjustedLeft = right + 10; // Add some space between the policy and the subheading div
    // if (adjustedLeft + subheadingDivWidth > viewportWidth) {
    //   adjustedLeft = viewportWidth - subheadingDivWidth - 10; // Adjust to keep within viewport
    // }

    setHoveredPolicyIndex(index);
    setHoveredPolicyPosition({ top: top, left: adjustedLeft });
  };

  // Function to handle mouse leave from policy
  const handleMouseLeave = () => {
    setHoveredPolicyIndex(null);
  };

  return (
    <nav className="flex bg-sky-100 justify-between p-10 font-semibold z-10">
      <div>
        <img
          className="-mt-3"
          src={logo}
          alt="UniversalConsultants"
          width={200}
        />
      </div>
      {isMobile ? (
        <div>
          <HamburgerIcon />
        </div>
      ) : (
        <div className="relative">
          <ul className="flex justify-between space-x-5">
            <li>
              <a href="#">Home</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#service">Service</a>
            </li>
            <li>
              <a href="#contact">ContactUs</a>
            </li>
            <li className="relative group">
              <a href="#insurance">Insurance</a>
              <div className="absolute left-0 hidden group-hover:block rounded-xl px-10 bg-white shadow-lg p-5 w-96">
                {policies.map((policy, index) => (
                  <div
                    key={index}
                    className="mb-4"
                    onMouseEnter={(e) => handleMouseEnter(index, e)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="flex items-center px-3 py-2 hover:bg-gray-100">
                      <img
                        src={policy.imageURL}
                        alt={policy.name}
                        className="w-6 h-6 mr-2"
                      />
                      <span className="font-bold w-60">{policy.name}</span>
                    </div>
                  </div>
                ))}
              </div>
            </li>
          </ul>
          {hoveredPolicyIndex !== null && (
            <div
              className="absolute mr-20 bg-gray-100 py-10 px-12 shadow-lg"
              onMouseEnter={() => setHoveredPolicyIndex(hoveredPolicyIndex)}
              onMouseLeave={handleMouseLeave}
              ref={policyDivRef}
            >
              <div className="py-5 px-2 bg-green-100 border-2  border-green-400 rounded-lg space-y-3 ">
                <p className="font-semibold text-2xl ">{policies[hoveredPolicyIndex].name}</p>
                <p className="text-sm">
                  {policies[hoveredPolicyIndex].description}
                </p>
              </div>
              <div>
                <ul className="mt-2">
                  {policies[hoveredPolicyIndex].subheadings.map(
                    (subheading, subIndex) => (
                      <li key={subIndex} className="text-xs bg-gray-300 px-3 py-2 my-2 rounded-lg border-2 border-gray-600 text-gray-600">
                        {subheading}
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          )}
        </div>
      )}
      <div className="max-sm:hidden">
        <JoinUsButton />
      </div>
    </nav>
  );
}

export default Navbar;
